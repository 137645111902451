<template>
  <div class="submitEnding-index">
    <div class="submitEnding-wrap">
      <div class="submit_img">
        <img src="@/assets/submitEnding/post_success_result.png" alt="">
      </div>
      <p class="submit_p">您的订单已提交成功</p>
      <p class="submit_order">点击前往订单中心</p>
      <div class="order_list_wrap">
        <p>
          <span>空调青岛</span>
          <span>订单号：<i class="order_no">BHO1900000364</i></span>
          <span class="copy">复制</span>
        </p>
        <p>
          <span>空调青岛</span>
          <span>订单号：<i class="order_no">BHO1900000364</i></span>
          <span class="copy">复制</span>
        </p>
      </div>
      <a-button type="primary"> 继续购物</a-button>
      <a-button>返回购物车</a-button>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{

    }
  },
  methods:{}
}
</script>
<style lang="less" scoped>
.submitEnding-index{
  font-family: PingFangSC-Medium;
  position: relative;
  background:#f7f7f7;
  overflow: hidden;
  .submitEnding-wrap{
    width:1188px;
    height:556px;
    background:#fff;
    margin:16px auto 40px;
    text-align: center;
    overflow: hidden;
    .submit_img{
      width: 130px;
      height:130px;
      margin:70px auto 24px;
    }
    .submit_p{
      font-size: 20px;
      color: #262626;
      letter-spacing: 0;
      line-height: 26px;
      font-weight: 500;
    }
    .submit_order{
      font-size: 16px;
      color: #00AAA6;
      letter-spacing: 0;
      line-height: 24px;
      margin:8px 0 16px;
      cursor: pointer;
    }
    .order_list_wrap{
      width: 390px;
      margin:0 auto 40px;
      background: #F7F7F7;
      padding:0 24px 12px 24px;
      box-sizing: border-box;
      overflow: hidden;
      & p{
        font-size: 12px;
        color: #777777;
        line-height: 20px;
        margin-top:12px;
        display: flex;
        justify-content: space-between;
        .order_no{
          color:#262626;
        }
        .copy{
          cursor: pointer;
        }
        .copy:hover{
          color:#00AAA6;
        }
      }
    }
    /deep/.ant-btn{
      width: 118px;
      height:40px;
      border-radius: 0;
      color: #00ABAF;
      border: 1px solid #00ABAF;
    }
    /deep/.ant-btn-primary{
      background: #00ABAF;
      color:#fff;
      margin-right: 16px;
    }
  }
  
}
</style>

